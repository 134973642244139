define([
    'positrace'
], function (Positrace) {

    var GeofenceDecorator = Positrace.Object.extend({

        initialize: function () {
            return this.model = this.getOption('model');
        },

        _getDisplaying() {
            const model = this.model;
            if (model.displaying) {
                return model.displaying;
            } else {
                model.displaying = new Positrace.Model({
                    is_visible: true
                });
                model.displaying.on('change:is_visible', function (displayingModel, value, options) {
                    model.trigger('change:is_visible', model, value, options);
                });

                return model.displaying;
            }
        },

        getIsVisible() {
            return this._getDisplaying().get('is_visible');
        },

        setIsVisible(value) {
            this._getDisplaying().set('is_visible', value);
        },

        getIcon: function () {
            return Base.icons.getGeofenceURL(this.model.getHEXColor());
        },

        isShowOnMap: function () {
            return this.model.isShowOnMap();
        },

        getName: function () {
            return this.model.getName() || '';
        },

        getDescription: function () {
            return this.model.getDescription() || '';
        },

        getSpeedingThreshold: function () {
            return this.model.get('speeding_threshold') || '';
        },

        getEncodedPath: function () {
            return this.model.getEncodedPolygon() || '';
        },

        getCoordinates: function () {
            return Base.geography.encodedPolylineToCoordinates(this.getEncodedPath());
        },

        setCoordinates: function (coordinates, options) {
            this.model.set('polygon', Base.geography.coordinatesToEncodedPolyline(coordinates), options);
            return this;
        },

        getBBox: function () {
            let coordinates = this.getCoordinates();
            if (!coordinates.length) {
                return null;
            }
            return Base.geography.coordinatesToBBox(coordinates);
        },

        getBounds: function () {
            let bbox = this.getBBox();
            if (!bbox) return null;
            let [southwestLng, southwestLat, northeastLng, northeastLat] = bbox;
            return Base.geography.createLatLngBounds([
                Base.geography.createLatLng([southwestLat, southwestLng]),
                Base.geography.createLatLng([northeastLat, northeastLng])
            ]);
        },

        getRGBColor: function () {
            return this.model.getHEXColor();
        },

    });

    GeofenceDecorator.decorate = function (model) {
        if (!model.decorator) {
            model.decorator = new GeofenceDecorator({
                model: model
            });
        }
        return model.decorator;
    };

    return GeofenceDecorator;

});